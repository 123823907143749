<template>

<div>
    <div v-if="isLoading" id="loading-cover">
        Loading...
    </div>

    <div class='container' id="master-container"
        :class="{'blurred': isLoading}">

        <div v-if='timecardData' 
            id='timecard-data'
            :class="{'timecard-clocked-in': !timecardData.clock_out, 'timecard-clocked-out': timecardData.clock_out}">
            <div v-if='timecardData.clock_out' class='timecard-title'>
                Clocked out 
            </div>
            <div v-else class='timecard-title'>
                Clocked in 
            </div>

            <div class='timecard-user'>
                {{ timecardData.user.name }}
            </div>

            <div v-if='timecardData.clock_out' class='timecard-datetime'>
                <div class='timecard-date'>
                    {{ timecardData.clock_out.toFormat( 'LLLL dd, yyyy' ) }}
                </div>
                <div class='timecard-time'>
                    {{ timecardData.clock_out.toFormat( 'hh:mm a' ) }}
                </div>
            </div>
            <div v-else class='timecard-datetime'>
                <div class='timecard-date'>
                    {{ timecardData.clock_in.toFormat( 'LLLL dd, yyyy' ) }}
                </div>
                <div class='timecard-time'>
                    {{ timecardData.clock_in.toFormat( 'hh:mm a' ) }}
                </div>
            </div>

            <div v-if='timecardData.clock_out'>
                <div class='timecard-summary'>Time worked: {{ timecardData.hours_worked }}</div>
                <div class='timecard-summary'>Accumulated: {{ timecardData.accumulated_hours }}</div>
            </div>

            <div class='back-button-container'>
                <button @click='backToKeypad' class='back-button'>Back</button>
            </div>

        </div>

        <div v-else>
            <div id='location-select-container'>
                <select id='location-select' placeholder='Select a location' v-model="selectedLocation">
                    <option v-for='location in locations' :key='location.id' :value='location.id'>{{ location.name }}</option>
                </select>
            </div>

            <div id='user-input'>
                <input id='user-code-input' type="text" v-model="input" autofocus>
            </div>

            <div id='error-container'>{{ error }}</div>

            <div id='keypad'>
                <div class='keypad-row'>
                    <button class='keypad-btn' @click='enterNumber(1)'>1</button>
                    <button class='keypad-btn' @click='enterNumber(2)'>2</button>
                    <button class='keypad-btn' @click='enterNumber(3)'>3</button>
                </div>
                <div class='keypad-row'>
                    <button class='keypad-btn' @click='enterNumber(4)'>4</button>
                    <button class='keypad-btn' @click='enterNumber(5)'>5</button>
                    <button class='keypad-btn' @click='enterNumber(6)'>6</button>
                </div>
                <div class='keypad-row'>
                    <button class='keypad-btn' @click='enterNumber(7)'>7</button>
                    <button class='keypad-btn' @click='enterNumber(8)'>8</button>
                    <button class='keypad-btn' @click='enterNumber(9)'>9</button>
                </div>

                <div class='keypad-row'>
                    <button class='keypad-btn' @click='clearNumber()'>Clear</button>
                    <button class='keypad-btn' @click='enterNumber(0)'>0</button>
                    <button class='keypad-btn keypad-submit-btn' @click='submitNumber()'>Enter</button>
                </div>
                
            </div>
        </div>
    </div>
</div>

</template>

<script>
import Vue from 'vue'
import LocationService from '../locations/LocationService';
import { DateTime } from 'luxon';

export default {

    data: () => ({
        input: '',
        locationService: new LocationService(),
        locations: [],
        selectedLocation: null,
        error: '',
        timecardData: null,
        isLoading: false,
        backToKeypadTimeout: null,
    }),

    methods: {
        enterNumber( val ) {
            this.input += val;
        },

        clearNumber() {
            this.input = '';
        },

        submitNumber() {
            this.error = "";
            localStorage.setItem( 'selectedLocation', this.selectedLocation )

            if ( this.selectedLocation == null ) {
                this.error = "Please select a location before clocking in";
                return;
            }

            if ( this.input.length < 4 ) {
                this.error = "Please enter a valid code";
                return;
            }

            this.isLoading = true

            Vue.http.post( 'punch', {
                code: this.input,
                location_id: this.selectedLocation,
                time: DateTime.local().toFormat( "yyyy-LL-dd'T'HH:mm:ss" )
            } ).then(
                response => {
                    this.input = ''

                    if ( response.body.data == null ) {
                        this.error = "The entered code is invalid."
                        return;
                    }

                    this.timecardData = response.body.data
                    this.timecardData.clock_in = new DateTime( this.timecardData.clock_in )
                    if ( this.timecardData.clock_out ) {
                        this.timecardData.clock_out = new DateTime( this.timecardData.clock_out )
                    }

                    this.backToKeypadTimeout = setTimeout( this.backToKeypad, 30000 )
                },
                err => {
                    this.error = "A server error has occured. Please try again."
                },
            ).finally(
                () => this.isLoading = false
            )
        },

        backToKeypad() {
            this.timecardData = null;
            if ( this.backToKeypadTimeout !== null ) {
                clearTimeout( this.backToKeypadTimeout )
                this.backToKeypadTimeout = null;
            }
        }
    },

    created() {
        this.locationService.list().then(
            response => {
                this.locations = response.body.data;
                this.locationsLoaded = true;

                localStorage.setItem( 'locations', this.locations )

                if ( localStorage.getItem( 'selectedLocation' ) ) {
                    this.selectedLocation = localStorage.getItem( 'selectedLocation' )
                }
            }
        )
    }
    
}
</script>


<style lang="less">

body {
    background-color: rgb( 247, 247, 247 );
    font-size: 1.1em;
}

#master-container {
    width: 100%;
    max-width: 800px;
}

.blurred {
    filter: blur(4px);
}

#loading-cover {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba( 200, 200, 200, 0.4 );
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.8em;
}

#title {
    font-size: 2.8em;
    text-align: center;
    margin-top: 40px;
}

#location-select-container {
    display: flex;

    #location-select {
        flex: 1;
        padding: 10px 20px;
        margin-top: 40px;
    }
}
    
#user-input {
    align-items: center;
    justify-content: center;
    display: flex;

    #user-code-input {
        font-size: 2.8em;
        min-height: 3em;
        text-align: center;
        margin-top: 30px;
        border: 1px solid rgb(200, 200, 200);
    }

    #user-code-input:focus {
        border: 3px solid black;
    }
}


#error-container {
    color: rgb( 153, 30, 30 );
    font-size: 1.8em;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

#keypad {
    
    .keypad-row {
        display: flex;
        width: 100%;

        .keypad-btn {
            flex: 1;
            border: 1px solid rgb( 240, 240, 240 );
            box-shadow: 0 2px 6px rgba( 150, 150, 150, 0.2 );
            font-size: 1.6em;
            align-items: center;
            text-align: center;

            padding: 20px;

            margin: 10px;

            background-color: white;
            user-select: none;
        }

        .keypad-btn:hover {
            background-color: rgb( 240, 240, 240 );
        }

        .keypad-btn:active {
            background-color: rgb( 220, 220, 220 );
        }

        .keypad-submit-btn {
            background-color: #62C370;
        }

        .keypad-submit-btn:hover {
            background-color: #89D294;
        }

        .keypad-submit-btn:active {
            background-color: #C4E9CA;
        }
    }

}

#timecard-data {
    margin-top: 50px;
    text-align: center;
    outline: 1px solid black;
    border-radius: 5px;
    box-shadow: 0px 2px 8px rgba( 0, 0, 0, 0.3 );
    padding: 0px 15px 30px;
    background-color: white;

    .timecard-title {
        font-size: 4em;
        margin: 20px 0;
        font-weight: bold;
    }

    .timecard-user {
        font-size: 1.5em;
        margin: 40px 0;
    }

    .timecard-date {
        font-size: 1.1em;
        margin-bottom: 10px;
    }

    .timecard-time {
        font-size: 3em;
        margin-bottom: 20px;
    }

    .back-button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .back-button {
            min-width: 100px;
            padding: 8px 0;
            margin-top: 10px;
            background-color: #74b9ff;
        }
    }
}


.timecard-clocked-out {
    border: 14px solid #74b9ff;
}

.timecard-clocked-in {
    border: 15px solid #62C370;
}

</style>