import EmployeeRoutes from './employees/routes';
import EmployeeComponent from './employees/EmployeeComponent.vue';
import LocationComponent from './locations/LocationComponent.vue';
import LocationRoutes from './locations/routes.js';
import ClockinComponent from './clockin/ClockinComponent.vue';
import AuthState from '../../states/AuthState.js';
import Settings from './settings/Settings.vue';

export default [
    
    { 
        path: '/', redirect: 'employee'
    },
    {
        path: '/clockin',
        component: ClockinComponent,
    },
    { 
        path: 'employee',
        component: EmployeeComponent,
        children: EmployeeRoutes,
        beforeEnter: ( to, from, next ) => {
            if ( AuthState.token.payload.user.type !== 'EMPLOYEE' ) {
                next();
            }
            else {
                next( '/status/403' );
            }
        }
    },
    {
        path: 'location',
        component: LocationComponent,
        children: LocationRoutes,
        beforeEnter: ( to, from, next ) => {
            if ( AuthState.token.payload.user.type !== 'EMPLOYEE' ) {
                next();
            }
            else {
                next( '/status/403' );
            }
        }
    },
    {
        path: 'settings',
        component: Settings,
        beforeEnter: ( to, from, next ) => {
            if ( AuthState.token.payload.user.type !== 'ADMIN' ) {
                next( '/status/403' );
            }
            else {
                next();
            }
        }
    },
]